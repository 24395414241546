import React from "react"
import { graphql } from "gatsby"
import { Disqus } from 'gatsby-plugin-disqus'
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html, timeToRead } = markdownRemark
  const config =  { siteUrl: 'yagudaev.com' }

  const path = frontmatter.path.replace(/(^\/)|(\/$)/, '')
  let disqusConfig = {
    url: ['https:/', config.siteUrl, path].join('/'),
    identifier: `/${path}`,
    title: frontmatter.title
  }

  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.description} cover={frontmatter.cover?.childImageSharp?.fluid?.src} />
      <h1>{frontmatter.title}</h1>
      <div style={{ marginBottom: 12 }}>
        <span>{frontmatter.date}</span>
        <span style={{ display: 'inline-block', margin: '0 4px', color: 'gray' }}>&#8729;</span>
        <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>{timeToRead} min read</span>
      </div>
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <Disqus config={disqusConfig} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        cover {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 600) {
              src
            }
          }
        }
      }
    }
  }
`
